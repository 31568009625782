/* eslint-disable */

import ace from 'brace';
import IC from 'stationeers-ic';

ace.define("ace/mode/sis_assembler_highlight_rules",["require","exports","module","ace/lib/oop","ace/mode/text_highlight_rules"], function(acequire, exports, module) {
  "use strict";
  
  var oop = acequire("../lib/oop");
  var TextHighlightRules = acequire("./text_highlight_rules").TextHighlightRules;
  
  var ic = new IC();

  var SISAssemblerHighlightRules = function() {
      let instructionFooter = "\\b(?:";
      let instructionSuffix = ")\\b";

      let instructions = ic.getInstructions();
      let instructionKeys = Object.keys(instructions);

      let instructionRegex = instructionFooter + instructionKeys.join("|") + instructionSuffix;

      this.$rules = { start: 
         [ { token: 'support.function.sis',
             regex: instructionRegex },
           { token: 'string.sis',
             regex: '\\b([A-Za-z0-9_]+)(:)' },
           { token: 'variable.language.sis',
             regex: '\\b(r*\\d+)\\b' },
          { token: 'variable.language.sis',
             regex: '\\b(d(b|r*\\d+))\\b' },
           { token: 'punctuation.definition.comment.sis',
             regex: '#',
             push: 
              [ { token: 'comment.line.number-sign.sis',
                  regex: '$',
                  next: 'pop' },
                { defaultToken: 'comment.line.number-sign.sis' } ] } ] }
      
      this.normalizeRules();
  };
  
  SISAssemblerHighlightRules.metaData = { fileTypes: [ 's', 'sis', 'spim', 'asm' ],
        keyEquivalent: '^~M',
        name: 'SIS Assembler',
        scopeName: 'source.sis' }
  
  
  oop.inherits(SISAssemblerHighlightRules, TextHighlightRules);
  
  exports.SISAssemblerHighlightRules = SISAssemblerHighlightRules;
  });
  
  ace.define("ace/mode/folding/cstyle",["require","exports","module","ace/lib/oop","ace/range","ace/mode/folding/fold_mode"], function(acequire, exports, module) {
  "use strict";
  
  var oop = acequire("../../lib/oop");
  var Range = acequire("../../range").Range;
  var BaseFoldMode = acequire("./fold_mode").FoldMode;
  
  var FoldMode = exports.FoldMode = function(commentRegex) {
      if (commentRegex) {
          this.foldingStartMarker = new RegExp(
              this.foldingStartMarker.source.replace(/\|[^|]*?$/, "|" + commentRegex.start)
          );
          this.foldingStopMarker = new RegExp(
              this.foldingStopMarker.source.replace(/\|[^|]*?$/, "|" + commentRegex.end)
          );
      }
  };
  oop.inherits(FoldMode, BaseFoldMode);
  
  (function() {
      
      this.foldingStartMarker = /(\{|\[)[^\}\]]*$|^\s*(\/\*)/;
      this.foldingStopMarker = /^[^\[\{]*(\}|\])|^[\s\*]*(\*\/)/;
      this.singleLineBlockCommentRe= /^\s*(\/\*).*\*\/\s*$/;
      this.tripleStarBlockCommentRe = /^\s*(\/\*\*\*).*\*\/\s*$/;
      this.startRegionRe = /^\s*(\/\*|\/\/)#region\b/;
      this._getFoldWidgetBase = this.getFoldWidget;
      this.getFoldWidget = function(session, foldStyle, row) {
          var line = session.getLine(row);
      
          if (this.singleLineBlockCommentRe.test(line)) {
              if (!this.startRegionRe.test(line) && !this.tripleStarBlockCommentRe.test(line))
                  return "";
          }
      
          var fw = this._getFoldWidgetBase(session, foldStyle, row);
      
          if (!fw && this.startRegionRe.test(line))
              return "start"; // lineCommentRegionStart
      
          return fw;
      };
  
      this.getFoldWidgetRange = function(session, foldStyle, row, forceMultiline) {
          var line = session.getLine(row);
          
          if (this.startRegionRe.test(line))
              return this.getCommentRegionBlock(session, line, row);
          
          var match = line.match(this.foldingStartMarker);
          if (match) {
              var i = match.index;
  
              if (match[1])
                  return this.openingBracketBlock(session, match[1], row, i);
                  
              var range = session.getCommentFoldRange(row, i + match[0].length, 1);
              
              if (range && !range.isMultiLine()) {
                  if (forceMultiline) {
                      range = this.getSectionRange(session, row);
                  } else if (foldStyle != "all")
                      range = null;
              }
              
              return range;
          }
  
          if (foldStyle === "markbegin")
              return;
  
          var match = line.match(this.foldingStopMarker);
          if (match) {
              var i = match.index + match[0].length;
  
              if (match[1])
                  return this.closingBracketBlock(session, match[1], row, i);
  
              return session.getCommentFoldRange(row, i, -1);
          }
      };
      
      this.getSectionRange = function(session, row) {
          var line = session.getLine(row);
          var startIndent = line.search(/\S/);
          var startRow = row;
          var startColumn = line.length;
          row = row + 1;
          var endRow = row;
          var maxRow = session.getLength();
          while (++row < maxRow) {
              line = session.getLine(row);
              var indent = line.search(/\S/);
              if (indent === -1)
                  continue;
              if  (startIndent > indent)
                  break;
              var subRange = this.getFoldWidgetRange(session, "all", row);
              
              if (subRange) {
                  if (subRange.start.row <= startRow) {
                      break;
                  } else if (subRange.isMultiLine()) {
                      row = subRange.end.row;
                  } else if (startIndent == indent) {
                      break;
                  }
              }
              endRow = row;
          }
          
          return new Range(startRow, startColumn, endRow, session.getLine(endRow).length);
      };
      
      this.getCommentRegionBlock = function(session, line, row) {
          var startColumn = line.search(/\s*$/);
          var maxRow = session.getLength();
          var startRow = row;
          
          var re = /^\s*(?:\/\*|\/\/)#(end)?region\b/;
          var depth = 1;
          while (++row < maxRow) {
              line = session.getLine(row);
              var m = re.exec(line);
              if (!m) continue;
              if (m[1]) depth--;
              else depth++;
  
              if (!depth) break;
          }
  
          var endRow = row;
          if (endRow > startRow) {
              return new Range(startRow, startColumn, endRow, line.length);
          }
      };
  
  }).call(FoldMode.prototype);
  
  });
  
  ace.define("ace/mode/sis_assembler",["require","exports","module","ace/lib/oop","ace/mode/text","ace/mode/sis_assembler_highlight_rules","ace/mode/folding/cstyle"], function(acequire, exports, module) {
  "use strict";
  
  var oop = acequire("../lib/oop");
  var TextMode = acequire("./text").Mode;
  var SISAssemblerHighlightRules = acequire("./sis_assembler_highlight_rules").SISAssemblerHighlightRules;
  var FoldMode = acequire("./folding/cstyle").FoldMode;
  
  var Mode = function() {
      this.HighlightRules = SISAssemblerHighlightRules;
      this.foldingRules = new FoldMode();
  };
  oop.inherits(Mode, TextMode);
  
  (function() {
      this.$id = "ace/mode/sis_assembler"
  }).call(Mode.prototype);
  
  exports.Mode = Mode;
  });
  